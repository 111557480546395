/* Ошибки валидатора */

.validation-error {
    color: var(--cl-magenta);
    font-size: 12px;
    line-height: 1.5;

    .field &,
    .select & {
        left: calc(var(--gs) * 2);
    }
}

.error-message {
    color: var(--cl-magenta);
    font-size: 14px;
    line-height: 20px;

    @media (--tablet) {
        font-size: 13px;
    }
}
